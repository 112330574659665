import React, { useState } from "react";
import "./Map.css";

import mapRestaurant from "../../assets/map/Restaurant_Zone3.png";
import mapArtisan from "../../assets/map/Artisan_Zone3.png";

import plate from "../../assets/map/round.png"
import { getRestoratorById } from "../../Pages/Restorators/restorators";
import DishMenuModal from "../Restorators/DishMenuModal";

function MarkerContent({ index, stand, setActiveMap, onMarkerClick, setTransition }) {
    const [open, setOpen] = useState(false);
    const restorator = getRestoratorById(stand.id);

    function handleMarkerClick(stand) {
        if (restorator) {
            setOpen(true);
        } else {
            onMarkerClick(stand);
        }
    }

    const handleSwitchMap = () => {
        setTransition("out");
        setTimeout(() => {
            setActiveMap((prev) => (prev === "restaurant" ? "artisan" : "restaurant"));
            setTransition("in");
        }, 500);
    };

    return (
        <div
            key={index}
            className="map-marker"
            style={{
                left: `${stand.x}%`,
                top: `${stand.y}%`,
            }}
            onClick={() => {
                if (stand.id === "arrow") {
                    handleSwitchMap();
                } else if (stand.menu === true) {
                    handleMarkerClick(stand);
                }
            }}
        >
            <div className="marker-image-container">
                {/* {stand.circle && <div className="marker-circle"></div>} */}
                {stand.circle && <img
                    src={plate}
                    alt="Plate"
                    className={stand.class && stand.class.includes("artisan-icon") ? "artisan-image" : "marker-image"}
                />}
                <img src={stand.icon} alt="Marker Icon" className={stand.class ? stand.class : "marker-icon"} />
                {restorator && (
                    <DishMenuModal open={open} setOpen={setOpen} title={restorator.name} menus={restorator.dishes} />
                )}
            </div>
        </div>
    );
}

const EventMap = ({ stands, standsArtisan, onMarkerClick }) => {
    const [activeMap, setActiveMap] = useState("restaurant");
    const [transition, setTransition] = useState("in");

    const getCurrentStands = () => {
        return activeMap === "restaurant" ? stands : standsArtisan;
    };

    return (
        <div className="event-map-scroll-container">
            <div className={`event-map-container ${activeMap} ${transition}`}>
                <img
                    src={activeMap === "restaurant" ? mapRestaurant : mapArtisan}
                    alt="Event Map"
                    className="event-map"
                />
                {getCurrentStands().map((stand, index) => (
                    <MarkerContent index={index} stand={stand} activeMap={activeMap} setActiveMap={setActiveMap} setTransition={setTransition} onMarkerClick={onMarkerClick}/>
                ))}
            </div>
        </div>
    );
};

export default EventMap;
