import SauceClicker from "../../Component/SauceClicker/SauceClicker.jsx";
import GameStartScreen from "../../Component/SauceClicker/GameStartScreen";
import GameOverScreen from "../../Component/SauceClicker/GameOverScreen";
import React, {
    useState,
    useEffect,
    useRef,
    memo,
    useCallback,
    useMemo,
} from "react";
import { Row, Col, Typography, Avatar, Progress } from "antd";
import Title from "antd/es/skeleton/Title.js";
const GAME_DURATION = 15;

const SauceClickersPage = () => {
    const [score, setScore] = useState(0);
    const [timeLeft, setTimeLeft] = useState(GAME_DURATION);
    const [gameOver, setGameOver] = useState(false);
    const [gameStarted, setGameStarted] = useState(false);
    const mouthStains = Array.from({ length: score }, () => ({
        top: `${Math.floor(Math.random() * 10) + 71}%`,
        left: `${Math.floor(Math.random() * 70) + 20}%`,
    }));

    const timerRef = useRef(null);

    const timeProgress = useMemo(() => {
        return Math.round((timeLeft / GAME_DURATION) * 100);
    }, [timeLeft]);

    const initializeGame = useCallback(() => {}, []);

    const handleTargetClick = () => {
        setScore((prevScore) => prevScore + 1);
    };

    const resetGame = useCallback(() => {
        setGameOver(false);
        setGameStarted(true);
        initializeGame();
        setTimeLeft(GAME_DURATION);
        setScore(0);

        if (timerRef.current) {
            clearInterval(timerRef.current);
        }

        timerRef.current = setInterval(() => {
            setTimeLeft((prevTime) => {
                if (prevTime <= 1) {
                    clearInterval(timerRef.current);
                    setGameOver(true);
                    return 0;
                }
                return prevTime - 1;
            });
        }, 1000);
    }, [initializeGame]);

    useEffect(() => {
        return () => {
            if (timerRef.current) {
                clearInterval(timerRef.current);
            }
        };
    }, []);

    if (!gameStarted) {
        return <GameStartScreen onStartGame={resetGame} />;
    }

    if (gameOver) {
        return <GameOverScreen score={score} onRestart={resetGame} />;
    }

    return (
        <Row justify="center" align="middle" style={{ textAlign: "center" }}>
            <Col xs={24} sm={20} md={16} lg={12} style={{ marginTop: "30px" }}>
                <Row justify="center" align="middle" gutter={[16, 16]}>
                    <Col>
                        <span
                            style={{ fontSize: "24px" }}
                            className="anton-regular"
                        >
                            Temps restant : &nbsp;
                        </span>
                        <span
                            style={{
                                fontSize: "24px",
                                color:
                                    timeProgress < 50
                                        ? "var(--primary-color)"
                                        : "black",
                            }}
                            className="anton-regular"
                        >
                            {timeLeft}s
                        </span>
                    </Col>
                    <Col>
                        <Title level={4} style={{ margin: 0 }}>
                            Score: {score}
                        </Title>
                    </Col>
                </Row>
                <Title level={4} style={{ marginTop: "20px" }}>
                    Click:
                </Title>
                <div
                    style={{
                        width: "100%",
                        height: "auto",
                        padding: "5vw",
                        boxSizing: "border-box",
                    }}
                >
                    <SauceClicker
                        onTargetClick={handleTargetClick}
                        score={score}
                        mouthStains={mouthStains}
                    />
                </div>
            </Col>
        </Row>
    );
};

export default SauceClickersPage;
