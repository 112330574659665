import React, {
    useState,
    useEffect,
    useRef,
    memo,
    useCallback,
    useMemo,
} from "react";
import { Row, Col, Typography, Avatar, Progress } from "antd";
import CharacterCanvas from "../../Component/FindFood/CharacterCanvas.jsx";
import GameStartScreen from "../../Component/FindFood/GameStartScreen";
import GameOverScreen from "../../Component/FindFood/GameOverScreen";
import TVFrame from "../../assets/character/old.svg";
import "./FindFoodPage.css";

const { Title } = Typography;

const characters = [
    { id: 1, name: "Vert", img: "/images/mff_male_green.png" },
    { id: 2, name: "Rouge", img: "/images/mff_male_red.png" },
    { id: 3, name: "Bleu", img: "/images/mff_male_blue.png" },
    { id: 4, name: "Jaune", img: "/images/mff_male_yellow.png" },
];

const MemoizedCharacterCanvas = memo(CharacterCanvas);

const MIN_DENSITY = 5;
const MAX_DENSITY = 15;
const MIN_SPEED = 1;
const MAX_SPEED = 5;
const GAME_DURATION = 60;

const FindFoodPage = () => {
    const [score, setScore] = useState(0);
    const [targetCharacter, setTargetCharacter] = useState(characters[0]);
    const [density, setDensity] = useState(10);
    const [speed, setSpeed] = useState(2);
    const [timeLeft, setTimeLeft] = useState(GAME_DURATION);
    const [foundCounts, setFoundCounts] = useState(
        characters.reduce((acc, char) => ({ ...acc, [char.name]: 0 }), {}),
    );
    const [gameOver, setGameOver] = useState(false);
    const [gameStarted, setGameStarted] = useState(false);

    const timerRef = useRef(null);
    const gameStateRef = useRef({
        density,
        speed,
        targetCharacter,
    });

    const characterImages = useMemo(
        () => characters.map((char) => char.img),
        [],
    );
    const movementFormula = useCallback((velocity) => velocity, []);
    const timeProgress = useMemo(() => {
        return Math.round((timeLeft / GAME_DURATION) * 100);
    }, [timeLeft]);

    const initializeGame = useCallback(() => {
        const newTargetCharacter =
            characters[Math.floor(Math.random() * characters.length)];
        const newDensity =
            Math.floor(Math.random() * (MAX_DENSITY - MIN_DENSITY + 1)) +
            MIN_DENSITY;
        const newSpeed = Math.random() * (MAX_SPEED - MIN_SPEED) + MIN_SPEED;

        setTargetCharacter(newTargetCharacter);
        setDensity(newDensity);
        setSpeed(newSpeed);

        gameStateRef.current = {
            density: newDensity,
            speed: newSpeed,
            targetCharacter: newTargetCharacter,
        };
    }, []);

    const handleTargetClick = useCallback(() => {
        setScore((prevScore) => prevScore + 10);
        setFoundCounts((prevCounts) => ({
            ...prevCounts,
            [gameStateRef.current.targetCharacter.name]:
                prevCounts[gameStateRef.current.targetCharacter.name] + 1,
        }));
        initializeGame();
    }, [initializeGame]);

    const resetGame = useCallback(() => {
        setGameOver(false);
        setGameStarted(true);
        initializeGame();
        setTimeLeft(GAME_DURATION);
        setScore(0);
        setFoundCounts(
            characters.reduce((acc, char) => ({ ...acc, [char.name]: 0 }), {}),
        );

        if (timerRef.current) {
            clearInterval(timerRef.current);
        }

        timerRef.current = setInterval(() => {
            setTimeLeft((prevTime) => {
                if (prevTime <= 1) {
                    clearInterval(timerRef.current);
                    setGameOver(true);
                    return 0;
                }
                return prevTime - 1;
            });
        }, 1000);
    }, [initializeGame]);

    useEffect(() => {
        return () => {
            if (timerRef.current) {
                clearInterval(timerRef.current);
            }
        };
    }, []);

    if (!gameStarted) {
        return <GameStartScreen onStartGame={resetGame} />;
    }

    if (gameOver) {
        return (
            <GameOverScreen
                score={score}
                foundCounts={foundCounts}
                onRestart={resetGame}
            />
        );
    }

    return (
        <Row justify="center" align="middle" className="page-find-food">
            <Col
                xs={24}
                sm={20}
                md={16}
                lg={12}
                style={{ textAlign: "center" }}
            >
                <div className="time-left">
                    <span className="anton-regular">
                        Temps restant : &nbsp;
                    </span>
                    <span
                        style={{
                            color:
                                timeProgress < 20
                                    ? "var(--primary-color)"
                                    : "black",
                        }}
                        className="anton-regular"
                    >
                        {timeLeft}s
                    </span>
                </div>
                <Row className="info-container">
                    <div className="find-container">
                        <Title className="find anton-regular">Trouver :</Title>
                        <Avatar
                            size={40}
                            shape="square"
                            style={{ zIndex: 5 }}
                            src={targetCharacter.img}
                            alt={targetCharacter.name}
                        />
                    </div>
                    <Title className="score anton-regular">
                        Score : {score}
                    </Title>
                </Row>
                <div
                    className="game-container"
                    style={{
                        width: "100%",
                        height: "400px",
                        position: "relative",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <img
                        src={TVFrame}
                        alt="Television Frame"
                        className="tv-frame"
                    />
                    <div className="game-zone">
                        <MemoizedCharacterCanvas
                            key="game-canvas"
                            density={density}
                            images={characterImages}
                            targetCharacter={targetCharacter}
                            movementFormula={movementFormula}
                            speed={speed}
                            onTargetClick={handleTargetClick}
                        />
                    </div>
                </div>
            </Col>
        </Row>
    );
};

export default FindFoodPage;
