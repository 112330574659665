import { Card, Flex, Modal, Typography } from 'antd';

const titleStyle = {
    color: 'white',
    fontSize: '2em',
    fontWeight: 'bolder',
    backgroundColor: 'red',
    padding: '2px 15px',
    borderRadius: '20px',
    border: "3px solid black",
    textAlign: 'center',
};

const menuItemStyle = {
    justifyContent: 'space-between',
    padding: '3px 10px',
    border: "3px solid black",
    borderRadius: '30px',
    margin: '5px 0',
    alignItems: 'center',
    fontWeight: 'bolder',
    backgroundColor: '#fbf2e7',
};

const priceStyle = {
    fontSize: '1.6em',
    backgroundColor: '#fab602',
    padding: '2px 7px',
    borderRadius: '50px',
    border: "3px solid black",
    minWidth: '80px',
    textAlign: 'center',
};

export default function DishMenuModal({title, menus, open, setOpen}) {
    return (
        <Modal
            open={open}
            onCancel={(event) => {
                event.stopPropagation();
                setOpen(false);
            }}
            footer={null}
            centered
            style={{border: "3px solid black", borderRadius: '10px'}}
        >
            <Flex style={{ justifyContent: 'center', width: '100%', paddingTop: '15px' }} vertical>
                <Typography.Title style={titleStyle}>
                    {title}
                </Typography.Title>
                {menus ? (
                    menus.map((menu, index) => (
                        <Flex key={index} style={menuItemStyle} gap={"small"}>
                            <Typography.Text style={{ fontSize: '1.5em' }}>
                                {menu.title}
                            </Typography.Text>
                            {menu.price && (
                                <Typography.Text style={priceStyle}>
                                    {menu.price}
                                </Typography.Text>
                            )}
                        </Flex>
                    ))
                ) : (
                    <Typography.Text style={{ fontSize: '1.5em', textAlign: "center", fontWeight: "bold" }}>Venez découvrir le menu sur notre stand !</Typography.Text>
                )}
            </Flex>
        </Modal>
    );
}
