import React from "react";
import { Row, Col, Typography, Button, Result } from "antd";
import {
    PlayCircleOutlined,
    ClockCircleOutlined,
    AimOutlined,
    TrophyOutlined,
} from "@ant-design/icons";

const { Title, Text } = Typography;

const GameStartScreen = ({ onStartGame }) => {
    return (
        <Row justify="center" align="middle" style={{ minHeight: "auto" }}>
            <Col xs={22} sm={20} md={16} lg={12}>
                <Result
                    className="anton-regular"
                    icon={
                        <PlayCircleOutlined
                            style={{ color: "#ff4d4f", fontSize: "72px" }}
                        />
                    }
                    title=<p style={{ fontSize: "48px" }}>
                        Bienvenue dans "Envoie la sauce"
                    </p>
                    subTitle={
                        <div style={{ textAlign: "left", padding: "20px" }}>
                            <Title
                                className="anton-regular"
                                level={4}
                                style={{
                                    fontSize: "30px",
                                    color: "#ff4d4f",
                                    marginBottom: "30px",
                                    textAlign: "center",
                                }}
                            >
                                Comment jouer :
                            </Title>

                            <Row gutter={[16, 24]}>
                                <Col span={24}>
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "16px",
                                        }}
                                    >
                                        <AimOutlined
                                            style={{
                                                fontSize: "24px",
                                                color: "#ff4d4f",
                                                flexShrink: 0,
                                            }}
                                        />
                                        <div>
                                            <Text
                                                className="anton-regular"
                                                strong
                                                style={{ fontSize: "20px" }}
                                            >
                                                Objectif :{" "}
                                            </Text>
                                            <Text style={{ fontSize: "16px" }}>
                                                Clique sur le pot de sauce le
                                                plus rapidement possible.
                                            </Text>
                                        </div>
                                    </div>
                                </Col>

                                <Col span={24}>
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "16px",
                                        }}
                                    >
                                        <ClockCircleOutlined
                                            style={{
                                                fontSize: "24px",
                                                color: "#ff4d4f",
                                                flexShrink: 0,
                                            }}
                                        />
                                        <div>
                                            <Text
                                                className="anton-regular"
                                                strong
                                                style={{ fontSize: "20px" }}
                                            >
                                                Temps :{" "}
                                            </Text>
                                            <Text style={{ fontSize: "16px" }}>
                                                Tu as 15 secondes pour faire le
                                                meilleur score possible.
                                            </Text>
                                        </div>
                                    </div>
                                </Col>

                                <Col span={24}>
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "16px",
                                        }}
                                    >
                                        <TrophyOutlined
                                            style={{
                                                fontSize: "24px",
                                                color: "#ff4d4f",
                                                flexShrink: 0,
                                            }}
                                        />
                                        <div>
                                            <Text
                                                className="anton-regular"
                                                strong
                                                style={{ fontSize: "20px" }}
                                            >
                                                Points :{" "}
                                            </Text>
                                            <Text style={{ fontSize: "16px" }}>
                                                Chaque clic correct te rapporte
                                                1 point. Essaie d'obtenir le
                                                meilleur score !
                                            </Text>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    }
                    extra={
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                width: "100%",
                            }}
                        >
                            <Button
                                type="primary"
                                size="large"
                                danger
                                onClick={onStartGame}
                                icon={<PlayCircleOutlined />}
                            >
                                Commencer le Jeu
                            </Button>
                        </div>
                    }
                />
            </Col>
        </Row>
    );
};

export default GameStartScreen;
