import React from "react";
import { Row, Image } from "antd";
import { CarouselCustom } from "../../Component/Carousel/Carousel.jsx";
import { ImportantStuffButton } from "../../Component/Buttons/Buttons.jsx";
import EventMap from "../../Component/Map/Map.jsx";

import LogoMFF from "../../assets/character/menColor.svg";

import "./Homepage.css";

import TestIcon from "../../assets/map/cheeseburger.png";
import arrow from "../../assets/map/right-arrow.png"
import wc from "../../assets/map/Toilettes.svg"

import andreucci from "../../assets/map/SVG/1.rucola.svg";
import aranciNono from "../../assets/map/SVG/Aranci-Nono.svg";
import bimBamBao from "../../assets/map/SVG/Bim-Bam-Bao.svg";
import cheescake from "../../assets/map/SVG/Cheescake.svg";
import chezVincent from "../../assets/map/SVG/Chez-Vincent.svg";
import creamyCookie from "../../assets/map/SVG/Creamy-Cookie.svg";
import dakarPassionSvg from "../../assets/map/SVG/dakar_passion.svg";
import forumLocal from "../../assets/map/SVG/Forum-du-Local.svg";
import haitiennementBon from "../../assets/map/SVG/Haitiennement-Bon.svg";
import losBuenosDias from "../../assets/map/SVG/Los-Buenos-Dias.svg";
import omnioCafe from "../../assets/map/SVG/Omnio-Cafe.svg";
import petitMarcel from "../../assets/map/SVG/Petit-Marcel.svg";
import rockTheBretzel from "../../assets/map/SVG/Rock-the-Bretzel.svg";
import saborArgentina from "../../assets/map/SVG/Sabor-Argentina.svg";
import teisuto from "../../assets/map/SVG/Teisuto.svg";
import usagiya from "../../assets/map/SVG/Usagiya.svg";
import vegalette from "../../assets/map/SVG/Vegalette.svg";

import pritoco from "../../assets/map/artisan/priotico.jpg";
import studio2920 from "../../assets/map/artisan/studio-2920.jpg";
import fluffy from "../../assets/map/artisan/fluffy-shop.jpg";
import bretzel from "../../assets/map/artisan/bretzel-arc-en-ciel.png";
import illustrAlasace from "../../assets/map/artisan/illustralasace.png";
import conserverie from "../../assets/map/artisan/petiteCantine.svg";
import heritage from "../../assets/map/artisan/heritage-hispanoia.png";
import nounka from "../../assets/map/artisan/nounta-poukl.jpg";
import mudra from "../../assets/map/artisan/mudra.avif";
import mayahua from "../../assets/map/artisan/maya-hua.jpg";
import dakarPassionPng from "../../assets/map/artisan/dakar-passion.jpg";
import barnei from "../../assets/map/artisan/barnei.jpg";
import coeurAiguille from "../../assets/map/artisan/coeur-aiguille.jpg";
import clara from "../../assets/map/artisan/clara-valdes.jpg";
import fleurChocolat from "../../assets/map/artisan/fleur-chocolat.jpg";
import atelierJess from "../../assets/map/artisan/atelier-jess.png";
import cabaneHerbes from "../../assets/map/artisan/cabane-aux-herbes.png";

import canape2 from "../../assets/map/mobilier/Grand-canape.svg";
import chaiseTable2 from "../../assets/map/mobilier/Chaise-grande-table.svg";
import table1 from "../../assets/map/mobilier/Table1.svg";
import bar from "../../assets/map/mobilier/mobiliers-8.png"
import fauteuil from "../../assets/map/mobilier/mobiliers-07.svg"

import textArtisan from "../../assets/map/Zone-Artisan-Gunny.svg"
import textRestaurant from "../../assets/map/Zone-Restaurant-Gunny.svg"

const HomePage = () => {
    const stands = [
        { x: 11, y: 24, icon: saborArgentina, id: "Sabor Argentina", circle: true, class: "marker-icon lower-icon", menu: true },
        { x: 17.5, y: 24, icon: haitiennementBon, id: "Haïtiennement Bon", circle: true, class: "marker-icon medium-icon", menu: true },
        { x: 24, y: 39, icon: rockTheBretzel, id: "Rock the Bretzel", circle: true, class: "marker-icon medium-icon", menu: true },
        { x: 36, y: 39, icon: bimBamBao, id: "Bim Bam Bao", circle: true, menu: true },

        { x: 50, y: 24, icon: chezVincent, id: "Chez Vincent", circle: true, class: "marker-icon medium-icon", menu: true },
        { x: 56.5, y: 24, icon: omnioCafe, id: "Omnino", circle: true, class: "marker-icon medium-icon", menu: true },
        { x: 64.5, y: 24, icon: andreucci, id: "Andreucci", circle: true, class: "marker-icon small-icon", menu: true },
        { x: 72, y: 42, icon: petitMarcel, id: "Petit Marcel", circle: true, menu: true },

        { x: 85, y: 42, icon: creamyCookie, id: "Creamy Cookie", circle: true, class: "marker-icon small-icon", menu: true },
        { x: 93, y: 42, icon: vegalette, id: "Vegalette", circle: true, class: "marker-icon small-icon", menu: true },

        { x: 72, y: 85, icon: cheescake, id: "Les Cheesecakes de la Forêt Noire", circle: true, class: "marker-icon", menu: true },
        { x: 66, y: 80, icon: teisuto, id: "Teisuto", circle: true, menu: true },

        { x: 43, y: 85, icon: aranciNono, id: "Aranci'Nonno", circle: true, menu: true },
        { x: 37, y: 85, icon: usagiya, id: "Usagiya", circle: true, menu: true },
        { x: 31, y: 85, icon: losBuenosDias, id: "Los Buenos Dias", circle: true, menu: true },
        { x: 25, y: 85, icon: forumLocal, id: "Forum du Local", circle: true, class: "marker-icon medium-icon forum-local-icon", menu: true },
        { x: 19, y: 85, icon: dakarPassionSvg, id: "Dakar Passion", circle: true, class: "marker-icon small-icon", menu: true },


        { x: 94, y: 58, icon: arrow, id: "arrow", circle: false, class: "marker-icon arrow", menu: false },
        { x: 84, y: 59.5, icon: textArtisan, id: "text-artisan", circle: false, class: "text-artisan", menu: false },
        { x: 54, y: 86, icon: wc, id: "wc", circle: false, class: "wc-icon", menu: false },


        { x: 5.5, y: 21, icon: fauteuil, id: "chill-zone", circle: false, class: "fauteuil-icon", menu: false },
        { x: 5.5, y: 32, icon: fauteuil, id: "chill-zone", circle: false, class: "fauteuil-icon rotate-180", menu: false },

        { x: 8, y: 76, icon: canape2, id: "chill-zone", circle: false, class: "furniture-icon", menu: false },
        { x: 7.7, y: 83.2, icon: table1, id: "chill-zone", circle: false, class: "furniture-icon", menu: false },
        { x: 7.4, y: 90, icon: canape2, id: "chill-zone", circle: false, class: "furniture-icon rotate-180", menu: false },
        { x: 12.5, y: 83, icon: fauteuil, id: "chill-zone", circle: false, class: "fauteuil-icon rotate-90", menu: false },

        { x: 8, y: 76, icon: canape2, id: "chill-zone", circle: false, class: "furniture-icon", menu: false },

        { x: 28.5, y: 36, icon: fauteuil, id: "chill-zone", circle: false, class: "fauteuil-icon", menu: false },
        { x: 31.5, y: 36, icon: fauteuil, id: "chill-zone", circle: false, class: "fauteuil-icon", menu: false },

        { x: 42.3, y: 24, icon: canape2, id: "chill-zone", circle: false, class: "furniture-icon rotate-270", menu: false },

        { x: 77, y: 37.5, icon: fauteuil, id: "chill-zone", circle: false, class: "fauteuil-icon", menu: false },
        { x: 80, y: 37.5, icon: fauteuil, id: "chill-zone", circle: false, class: "fauteuil-icon", menu: false },

        { x: 79, y: 81.5, icon: canape2, id: "chill-zone", circle: false, class: "furniture-icon rotate-270", menu: false },
        { x: 84, y: 74, icon: canape2, id: "chill-zone", circle: false, class: "furniture-icon", menu: false },
        { x: 83.7, y: 82.5, icon: table1, id: "chill-zone", circle: false, class: "furniture-icon", menu: false },
        { x: 82, y: 90.5, icon: fauteuil, id: "chill-zone", circle: false, class: "fauteuil-icon rotate-180", menu: false },
        { x: 85.5, y: 90.5, icon: fauteuil, id: "chill-zone", circle: false, class: "fauteuil-icon rotate-180", menu: false },
        { x: 94, y: 80, icon: bar, id: "chill-zone", circle: false, class: "bar-icon rotate-270", menu: false },
    ];

    const standsArtisan = [
        { x: 34, y: 17, icon: pritoco, id: "1", circle: true, class: "artisan-icon artisan-small-icon", menu: false },
        { x: 40, y: 17, icon: studio2920, id: "2", circle: true, class: "artisan-icon artisan-small-icon", menu: false },
        { x: 46, y: 17, icon: fluffy, id: "3", circle: true, class: "artisan-icon artisan-small-icon", menu: false },
        { x: 52, y: 17, icon: bretzel, id: "4", circle: true, class: "artisan-icon artisan-small-icon", menu: false },
        { x: 58, y: 17, icon: illustrAlasace, id: "5", circle: true, class: "artisan-icon artisan-small-icon", menu: false },
        { x: 64, y: 17, icon: conserverie, id: "6", circle: true, class: "artisan-icon artisan-small-icon", menu: false },
        { x: 67, y: 28, icon: heritage, id: "7", circle: true, class: "artisan-icon artisan-small-icon", menu: false },

        { x: 38, y: 29, icon: nounka, id: "16", circle: true, class: "artisan-icon artisan-small-icon", menu: false },
        { x: 44, y: 29, icon: mudra, id: "14", circle: true, class: "artisan-icon artisan-small-icon", menu: false },
        { x: 50, y: 29, icon: mayahua, id: "12", circle: true, class: "artisan-icon artisan-small-icon", menu: false },
        { x: 56, y: 29, icon: dakarPassionPng, id: "10", circle: true, class: "artisan-icon artisan-small-icon", menu: false },
        { x: 62, y: 29, icon: barnei, id: "8", circle: true, class: "artisan-icon artisan-small-icon", menu: false },

        { x: 38, y: 40, icon: coeurAiguille, id: "17", circle: true, class: "artisan-icon artisan-small-icon", menu: false },
        { x: 44, y: 40, icon: clara, id: "15", circle: true, class: "artisan-icon artisan-small-icon", menu: false },
        { x: 50, y: 40, icon: fleurChocolat, id: "13", circle: true, class: "artisan-icon artisan-small-icon", menu: false },
        { x: 56, y: 40, icon: atelierJess, id: "11", circle: true, class: "artisan-icon artisan-small-icon", menu: false },
        { x: 62, y: 40, icon: cabaneHerbes, id: "9", circle: true, class: "artisan-icon artisan-small-icon", menu: false },

        
        { x: 32, y: 52, icon: arrow, id: "arrow", class: "reverse-arrow", menu: false },
        { x: 42, y: 51.5, icon: textRestaurant, id: "text-restaurant", circle: false, class: "text-restaurant", menu: false },


        { x: 40, y: 69, icon: chaiseTable2, id: "chill-zone", circle: false, class: "furniture-icon", menu: false },
        { x: 40, y: 82, icon: chaiseTable2, id: "chill-zone", circle: false, class: "furniture-icon", menu: false },
        { x: 48, y: 69, icon: chaiseTable2, id: "chill-zone", circle: false, class: "furniture-icon", menu: false },
        { x: 48, y: 82, icon: chaiseTable2, id: "chill-zone", circle: false, class: "furniture-icon", menu: false },
        { x: 56, y: 56, icon: chaiseTable2, id: "chill-zone", circle: false, class: "furniture-icon", menu: false },

        { x: 63.5, y: 53, icon: canape2, id: "chill-zone", circle: false, class: "sofa-icon", menu: false },
        { x: 63.2, y: 59, icon: table1, id: "chill-zone", circle: false, class: "sofa-icon", menu: false },
        { x: 67.2, y: 59, icon: fauteuil, id: "chill-zone", circle: false, class: "fauteuil-icon rotate-90", menu: false },
    ];


    const handleMarkerClick = (marker) => {
        console.log(`Marker clicked: ${marker.id}`);
    };

    return (
        <Row
            style={{
                display: "flex",
                textAlign: "center",
            }}
        >
            <p
                className="anton-regular title"
            >
                Mulhouse Food Festival
            </p>
            <p
                className="anton-regular title-second"
            >
                MFF
            </p>
            <img
                src={LogoMFF}
                alt="Logo MFF"
                className="logo"
            />
            <CarouselCustom />
            <div
                style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
                className="desktopview"
            >
                <ImportantStuffButton
                    title={"je chope mon entrée"}
                    onclick={() =>
                        window.open(
                            "https://my.weezevent.com/mff-2-mulhouse-food-festival",
                        )
                    }
                />
            </div>
            <EventMap
                stands={stands} standsArtisan={standsArtisan} onMarkerClick={handleMarkerClick} />
        </Row>
    );
};

export default HomePage;
