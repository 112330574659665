import React from "react";
import { useOrientation } from "react-use";
import { InstagramFilled, FacebookFilled } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { Layout, Button, Col } from "antd";

import "./Footer.css";

import menColor from "../../assets/character/menColor.png";
import EpitechLogo from "../../assets/logo/EPI-LOGO-2023-QUADRI.svg";
import xBrush from "../../assets/scribblesmears.svg";
import separationFooter from "../../assets/brush_footer.svg";

const { Footer } = Layout;

const MyFooter = () => {
    const navigate = useNavigate();

    return (
        <Footer
            className="footer-container"
            style={{
                backgroundColor: "transparent",
                color: "#343a40",
                borderRadius: "10rem 10rem 0 0",
                userSelect: "none",
            }}
        >
            <Col className="button-container">
                <Button
                    type="link"
                    style={{ color: "#343a40" }}
                    icon={<FacebookFilled />}
                    className="button-home"
                    onClick={() => window.open("https://www.facebook.com/mulhousefoodfestival/?locale=fr_FR")}
                />
                <Button
                    type="link"
                    style={{ color: "#343a40" }}
                    icon={<InstagramFilled />}
                    className="button-home"
                    onClick={() => window.open("https://www.instagram.com/mulhouse.foodfestival")}
                />
            </Col>
            <img src={separationFooter} alt="separationFooter" className="separation-footer" />
            <div className="image-container">
                <img
                    src={EpitechLogo}
                    alt="EpitechLogo"
                    className="epitech-logo" 
                    onClick={() =>
                        window.open(
                            "https://www.epitech.eu/ecole-informatique-mulhouse/",
                        )
                    }
                />
                <img src={xBrush} alt="xBrush" className="x-brush"
                />
                <img
                    src={menColor}
                    alt="menColor"
                    className="men-image"
                    onClick={() => navigate("/home")}
                />
            </div>
            <Button
                type="link"
                style={{ color: "#343a40" }}
                onClick={() => navigate("/legalmention")}
                className="button-legal-mention"
            >
                Mentions légales
            </Button>
        </Footer>
    );
};

export default MyFooter;
