import React from 'react';
import { Typography, Row, Carousel } from 'antd';

import '../../Style/font.css';
import './Carousel.css';

const importAll = (requireContext) => requireContext.keys().map(requireContext);
const images = importAll(require.context('../../../assets/pictures/carousel', false, /\.(png|jpe?g|svg)$/));

const Days = ({ day }) => (
    <Typography.Text
        style={{
            color: 'var(--background-color)',
            backgroundColor: 'var(--secondary-color)',
            padding: '0.3rem',
            borderRadius: '100rem',
            border: '3px solid var(--text-color)',
            fontSize: '1.5rem',
            margin: '0 0.2rem',
        }}
        className="poppins-bold"
    >
        {day}
    </Typography.Text>
);

const DateInfo = () => (
    <div
        style={{
            position: 'absolute',
            width: '100%',
            textAlign: 'end',
            marginTop: '1rem',
            display: 'flex',
            flexDirection: 'column',
        }}
    >
        <div>
            <Typography.Text
                style={{
                    margin: '1rem',
                    color: 'var(--text-color)',
                    backgroundColor: 'var(--background-color)',
                    paddingLeft: '1rem',
                    paddingRight: '1rem',
                    borderRadius: '100rem',
                    border: '3px solid var(--text-color)',
                    fontSize: '1.5rem',
                }}
                className="poppins-bold"
            >
                <Days day={18} />
                &amp;
                <Days day={19} />
                JANV. 25
            </Typography.Text>
        </div>
        <div
            style={{
                marginTop: '0.3rem',
            }}
        >
            <Typography.Text
                style={{
                    margin: '1rem',
                    color: 'var(--background-color)',
                    backgroundColor: 'var(--primary-color)',
                    paddingLeft: '1rem',
                    paddingRight: '1rem',
                    borderRadius: '100rem',
                    border: '3px solid var(--text-color)',
                    fontSize: '1.5rem',
                }}
                className="poppins-bold"
            >
                MOTOCO
            </Typography.Text>
        </div>
    </div>
);

const CarouselCustom = () => {
    return (
        <Row
            style={{
                width: '100%',
                borderTop: '3px solid var(--text-color)',
                borderBottom: '3px solid var(--text-color)',
            }}
        >
            <Carousel
                style={{
                    width: '100vw',
                }}
                autoplay
                arrows
                value='none'
                dots={false}
            >
                {images.map((image, index) => (
                    <Row
                        key={index}
                        className="carousel-row"
                    >
                        <img
                            src={image}
                            alt={`Slide ${index + 1}`}
                            className="carousel-image"
                        />
                    </Row>
                ))}
            </Carousel>
            <DateInfo />
        </Row>
    );
};

export { CarouselCustom };
