import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './Pages/Home/HomePage.jsx';
import FindFoodPage from './Pages/FindFood/FindFoodPage.jsx';
import SauceClickersPage from './Pages/SauceClicker/SauceClickerPage.jsx';
import Sidebar from './Component/Sidebar/Sidebar';
import RestoratorsPage from './Pages/Restorators/RestoratorsPage.jsx';
import InfosPage from './Pages/Infos/InfosPage.jsx';
import MyFooter from './Component/Footer/Footer.jsx';
import LegalMention from './Pages/LegalMention/LegalMention.jsx';

const App = () => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
      }}
    >
      <Router>
        <Sidebar />
        <div style={{ flex: 1, }}>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/home" element={<HomePage />} />
            <Route path="/restorators" element={<RestoratorsPage />} />
            <Route path="/infos" element={<InfosPage />} />
            <Route path="/findmff" element={<FindFoodPage />} />
            <Route path="/clickmff" element={<SauceClickersPage />} />
            <Route path="/legalmention" element={<LegalMention />} />
          </Routes>
        </div>
        <MyFooter />
      </Router>
    </div>
  );
};

export default App;
