import React from 'react';
import { Typography } from 'antd';

import '../../Style/font.css';
import './Button.css';

const ImportantStuffButton = ({ title, onclick }) => {
    return (
        <button
            onClick={onclick}
            style={{
                background: 'var(--background-color)',
                borderRadius: '100rem',
                border: '4px solid var(--text-color)',
            }}
            className="desktopview-button"
        >
            <Typography.Text
                style={{
                  margin: '1rem',
                  color: 'var(--text-color)',
                  fontSize: '1.5rem',
                }}
                className="poppins-bold"
            >
                {title.toUpperCase()}
            </Typography.Text>
        </button>
    );
}

export { ImportantStuffButton }
