import { Flex, Typography } from "antd";
import React, { useRef, useState, useEffect } from "react";
import { useOrientation } from "react-use";
import { useMediaQuery } from "react-responsive";
import "../../Style/font.css";
import { restorators } from "./restorators";
import RestoratorTitle from "../../Component/Restorators/RestoratorTitle";

import "./RestoratorsPage.css";

import divider from "../../assets/brush_footer.svg";
import salt from "../../assets/character/sel+grain.svg";
import cookie from "../../assets/character/cookies.svg";
import drink from "../../assets/character/soda.svg";

const infoStyle = {
    fontSize: "1.2em",
    fontStyle: "italic",
    textAlign: "center",
};

function RestoratorsPage() {
    const { type } = useOrientation();

    const [saltPosition, setSaltPosition] = useState({ top: 0, left: 0 });
    const [cookiePosition, setCookiePosition] = useState({ top: 0, left: 0 });
    const [drinkPosition, setDrinkPosition] = useState({ top: 0, left: 0 });

    const isMobileOrTablet = useMediaQuery({ query: "(max-width: 1224px)" });
    const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
    const isTablet = useMediaQuery({ query: "(max-width: 1224px)" });
    const isPortrait = type !== "landscape-primary";

    const saltRef = useRef(null);
    const cookieRef = useRef(null);
    const drinkRef = useRef(null);

    const getPosition = () => {
        if (saltRef.current) {
            const { top, left, right, bottom } = saltRef.current.getBoundingClientRect();
            const width = right - left;
            const height = bottom - top;
            setSaltPosition({ top: top + height / 2, left: left + width / 2 });
        }
        if (cookieRef.current) {
            const { top, left, right } = cookieRef.current.getBoundingClientRect();
            const width = right - left;
            setCookiePosition({ top: top, left: left + width / 2 });
        }
        if (drinkRef.current) {
            const { top, left, right } = drinkRef.current.getBoundingClientRect();
            const width = right - left;
            setDrinkPosition({ top: top, left: left + width / 2 });
        }
    }

    useEffect(() => {
        getPosition();
    }, []);

    useEffect(() => {
        getPosition();
    }, [window.innerWidth]);

    return (
        <Flex align="center" vertical style={{ marginTop: "50px", marginBottom: "50px", padding: "20px" }}>
            <Typography.Title className="anton-regular">Les Restaurateurs</Typography.Title>
            <Typography.Text style={infoStyle}>Cliquez sur un restaurateur pour voir son menu !</Typography.Text>
            <Flex
                justify="center"
                vertical
                className="restorators-container"
                style={{ alignItems: "center", marginTop: "2rem" }}
            >
                <Flex ref={saltRef} vertical className="menu">
                    <Typography.Title level={2} className="anton-regular">Le salé</Typography.Title>
                    {restorators.salted.map((restorator, index) => (
                        <RestoratorTitle restorator={restorator} key={index} />
                    ))}
                </Flex>
                <img src={divider} alt="divider" className="divider-menu" />
                <img src={divider} alt="divider" className="menu-separator" />
                <Flex vertical className="menu">
                    <Typography.Title level={2} className="anton-regular" ref={cookieRef}>Le sucré</Typography.Title>
                    {restorators.sweet.map((restorator, index) => (
                        <RestoratorTitle restorator={restorator} key={index} />
                    ))}
                    <img src={divider} alt="divider" className="menu-separator" />
                    <Typography.Title level={2} className="anton-regular" ref={drinkRef}>Le Glou-Glou</Typography.Title>
                    {restorators.drinks.map((restorator, index) => (
                        <RestoratorTitle restorator={restorator} key={index} />
                    ))}
                </Flex>
            </Flex >
            <img
                src={salt}
                alt="salt"
                className={`salt`}
                style={{
                    display: !isPortrait && isMobileOrTablet ? "none" : "",
                    top: saltPosition.top,
                    left: isMobileOrTablet ? "-10%" : "-2%"
                }}
            />
            <img
                src={cookie}
                alt="cookie"
                className={`cookie`}
                style={{
                    display: !isPortrait && isMobileOrTablet ? "none" : "",
                    top: cookiePosition.top,
                    left: isMobile ? "70%" : "80%"
                }}
            />
            <img
                src={drink}
                alt="drink"
                className={`drink`}
                style={{
                    display: !isPortrait && isMobileOrTablet ? "none" : "", 
                    top: isMobileOrTablet ? drinkPosition.top : drinkPosition.top - 75,
                    left: isMobile ? "-10%" : isTablet ? "80%" : "70%",
                }}
            />
        </Flex>
    );
}

export default RestoratorsPage;
