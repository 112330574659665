import { Button, Typography } from "antd";
import { useState } from "react";
import DishMenuModal from "./DishMenuModal";

function RestoratorTitle({ restorator, key }) {
    const [open, setOpen] = useState(false);

    const titleStyle = {
        fontSize: "1.45em",
        margin: "5px 15px",
        cursor: "pointer"
    };

    return (
        <>
            <Typography.Text key={key} onClick={() => setOpen(true)} style={titleStyle}>{restorator.name}</Typography.Text>
            {restorator && (
                <DishMenuModal title={restorator.name} menus={restorator.dishes} open={open} setOpen={setOpen}/>
            )}
        </>
    );
}

export default RestoratorTitle;