import React from "react";
import { Row, Col, Typography, Button, Result } from "antd";
import { TrophyOutlined, ReloadOutlined } from "@ant-design/icons";

const { Title } = Typography;

const GameOverScreen = ({ score, onRestart }) => {
    return (
        <Row justify="center" align="middle" style={{ minHeight: "auto" }}>
            <Col xs={24} sm={20} md={16} lg={12}>
                <Result
                    icon={
                        <TrophyOutlined
                            style={{ color: "#ff4d4f", fontSize: "72px" }}
                        />
                    }
                    title="Partie terminée !"
                    subTitle={
                        <div style={{ textAlign: "center", padding: "20px" }}>
                            <Title className="anton-regular" level={1}>
                                Score final : {score}
                            </Title>
                        </div>
                    }
                    extra={
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                width: "100%",
                            }}
                        >
                            <Button
                                type="primary"
                                danger
                                size="large"
                                onClick={onRestart}
                                icon={<ReloadOutlined />}
                            >
                                Rejouer
                            </Button>
                        </div>
                    }
                />
            </Col>
        </Row>
    );
};

export default GameOverScreen;
