export const restorators = {
    salted: [
        {
            name: "Aranci'Nonno",
            dishes: [
                {
                    title: "Arancini Ragu",
                    price: "5.50",
                    type: "meat"
                },
                {
                    title: "Arancini Quattro formaggi",
                    price: "5.50",
                    type: "vegetarian"
                },
                {
                    title: "Arancini Spinaci",
                    price: "5.50",
                    type: "vegetarian"
                },
                {
                    title: "Arancini Pomodoro",
                    price: "5.50",
                    type: "vegetarian"
                },
                {
                    title: "Arancini Tartare di Tonno",
                    price: "6.50",
                    type: "fish"
                }
            ]
        },
        {
            name: "Bim Bam Bao",
            dishes: [
                {
                    title: "Gua Bao Chicken Katsu",
                    price: "7.00"
                },
                {
                    title: "Gua Bao Poulet Curry Vert",
                    price: "7.00"
                },
                {
                    title: "Gua Bao Pulled Pork",
                    price: "7.00"
                },
                {
                    title: "Gua Bao Porc Char Siu",
                    price: "7.00"
                },
                {
                    title: "Gua Bao Poulet Satay",
                    price: "7.00"
                },
                {
                    title: "Gua Bao Végé (sauce au choix)",
                    price: "7.00"
                }
            ]
        },
        {
            name: "Los Buenos Dias",
            dishes: [
                {
                    title: "Tortilla de Patatas",
                    price: "5.00"
                },
                {
                    title: "Patatas Bravas et ses deux sauces maison",
                    price: "6.00"
                },
                {
                    title: "Gyozas maison de poulet effiloché (4 pièces)",
                    price: "6.00"
                },
                {
                    title: "Croquetas de truffe noire d'Espagne (3 pièces)",
                    price: "7.00"
                },
                {
                    title: "Paella de la Casa",
                    price: "15.00"
                }
            ]
        },
        {
            name: "Sabor Argentina"
        },
        {
            name: "Petit Marcel",
            dishes: [
                {
                    title: "Gyozas de poulet rôti (4 pièces)",
                    price: "6.00"
                },
                {
                    title: "Ramen au porc chashu",
                    price: "8.00"
                },
                {
                    title: "Ramen au boeuf fondant",
                    price: "8.00"
                },
                {
                    title: "Vapeur de crevettes sauce vietnamienne",
                    price: "6.00"
                },
            ]
        },
        {
            name: "Dakar Passion",
            dishes: [
                {
                    title: "Yassa au Poulet",
                },
                {
                    title: "Mafé au Boeuf",
                },
                {
                    title: "Vegan Afro (accras, plantais, riz et sauce tomate)",
                },
                {
                    title: "Foufou Sauce Graine"
                }
            ]
        },
        {
            name: "Chez Vincent"
        },
        {
            name: "Forum du Local"
        },
        {
            name: "Vegalette",
            dishes: [
                {
                    title: "Kebab Vegan",
                    price: "9.50"
                },
                {
                    title: "Dürüm Vegan",
                    price: "9.50"
                },
                {
                    title: "Apéro ou Petite Faim",
                    price: "5.00"
                }
            ]
        },
        {
            name: "Haïtiennement Bon",
            dishes: [
                {
                    title: "Portion d'Accras de Morue",
                    price: "5.00"
                },
                {
                    title: "Portion d'Accras de Malanga",
                    price: "5.00"
                },
                {
                    title: "2 Pate kode au thon",
                    price: "7.00"
                },
                {
                    title: "Riz djondjon Poulet frit",
                    price: "10.00"
                },
                {
                    title: "Portion de bananes plantain",
                    price: "5.00"
                },
                {
                    title: "Portion de poulet",
                    price: "5.00"
                }
            ]
        },
        {
            name: "Andreucci",
            dishes: [
                {
                    title: "Pinsa Coppa",
                    price: "14.00"
                },
                {
                    title: "Pinsa Vegetariana",
                    price: "13.00"
                },
                {
                    title: "Pinsa Andreucci"
                },
                {
                    title: "Pinsa Piccante"
                }
            ]
        }
    ],
    sweet: [
        {
            name: "Ommino"
        },
        {
            name: "Rock the Bretzel",
            dishes: [
                {
                    title: "Cookies Over the Top",
                    price: "4.00"
                },
                {
                    title: "Cupcakes qui Claquent !",
                    price: "3.00"
                },
                {
                    title: "Brookies Scandaleux",
                    price: "5.00"
                },
                {
                    title: "Brownies Ultra Fondants",
                    price: "3.00"
                },
                {
                    title: "Magnum Cake (Promis, Pas Une Glace !)",
                    price: "4.00"
                },
                {
                    title: "Macarons Trop Mignons",
                    price: "2.00"
                }
            ]
        },
        {
            name: "Les Cheesecakes de la Forêt Noire",
            dishes: [
                {
                    title: "Cheesecake Classique petite part (150g)",
                    price: "4.00"
                },
                {
                    title: "Cheesecake Classique grande part (225g)",
                    price: "5.00"
                },
                {
                    title: "Cheesecake Pomme-Cannelle petite part (150g)",
                    price: "5.50"
                },
                {
                    title: "Cheesecake Pomme-Cannelle grande part (225g)",
                    price: "6.50"
                },
                {
                    title: "Cheesecake Cerises petite part (150g)",
                    price: "4.50"
                },
                {
                    title: "Cheesecake Cerises grande part (225g)",
                    price: "5.50"
                },
                {
                    title: "Cheesecake Pavot petite part (150g)",
                    price: "4.50"
                },
                {
                    title: "Cheesecake Pavot grande part (225g)",
                    price: "5.50"
                },
                {
                    title: "Cheesecake Mandarine petite part (150g)",
                    price: "5.00"
                },
                {
                    title: "Cheesecake Mandarine grande part (225g)",
                    price: "6.00"
                }
            ]
        },
        {
            name: "Creamy Cookie",
            dishes: [
                {
                    title: "Simply the Best"
                },
                {
                    title: "Nut' Lover",
                },
                {
                    title: "Peanut Butter",
                },
                {
                    title: "3 Choco",
                },
                {
                    title: "Pistachio Blanco",
                },
                {
                    title: "Dark Pistachio",
                },
                {
                    title: "Matcha",
                },
                {
                    title: "Salty",
                },
                {
                    title: "Choco Blanco",
                },
                {
                    title: "Like a Brownie",
                },
                {
                    title: "Like a Snickers",
                },
                {
                    title: "Edition MFF",
                },
                {
                    title: "Edition Janvier",
                },
            ]
        },
        {
            name: "Usagiya"
        }
    ],
    drinks: [
        {
            name: "Omnino"
        },
        {
            name: "Teisuto"
        },
        {
            name: "L'Alsace en Cave"
        },
        {
            name: "MFF"
        }
    ]
};

export function getRestoratorById(id) {
    let ret = restorators.salted.find(restorator => restorator.name === id);
    if (ret) return ret;
    ret = restorators.sweet.find(restorator => restorator.name === id);
    if (ret) return ret;
    ret = restorators.drinks.find(restorator => restorator.name === id);
    return ret;
}
