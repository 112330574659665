import React from "react";
import { Row, Col, Typography, Button, Result } from "antd";
import { TrophyOutlined, ReloadOutlined } from "@ant-design/icons";

const { Title, Text } = Typography;

const GameOverScreen = ({ score, foundCounts, onRestart }) => {
    return (
        <Row justify="center" align="middle" style={{ minHeight: "auto" }}>
            <Col xs={24} sm={20} md={16} lg={12}>
                <Result
                    icon={
                        <TrophyOutlined
                            style={{ color: "#ff4d4f", fontSize: "72px" }}
                        />
                    }
                    title="Partie terminée !"
                    subTitle={
                        <div style={{ textAlign: "center", padding: "20px" }}>
                            <Title level={1} className="anton-regular">
                                Score final : {score}
                            </Title>
                            <div style={{ marginTop: "30px" }}>
                                <Title
                                    level={2.5}
                                    className="anton-regular"
                                    style={{
                                        marginBottom: "20px",
                                        color: "#ff4d4f",
                                    }}
                                >
                                    Personnages trouvés :
                                </Title>
                                {Object.entries(foundCounts).map(
                                    ([name, count]) => (
                                        <div
                                            key={name}
                                            style={{
                                                margin: "15px 0",
                                                fontSize: "16px",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                gap: "8px",
                                            }}
                                        >
                                            <Text
                                                strong
                                                className="anton-regular"
                                                style={{ fontSize: "24px" }}
                                            >
                                                {name} :{" "}
                                            </Text>
                                            <Text>{count} fois</Text>
                                        </div>
                                    ),
                                )}
                            </div>
                        </div>
                    }
                    extra={
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                width: "100%",
                            }}
                        >
                            <Button
                                type="primary"
                                danger
                                size="large"
                                onClick={onRestart}
                                icon={
                                    <ReloadOutlined
                                        style={{ fontSize: "20px" }}
                                    />
                                }
                            >
                                Rejouer
                            </Button>
                        </div>
                    }
                />
            </Col>
        </Row>
    );
};

export default GameOverScreen;
