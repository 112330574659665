import React, { useState, useEffect } from "react";
import { MenuOutlined } from "@ant-design/icons";
import { Button, Menu } from "antd";
import { ConfigProvider } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import "../../Font.css";

const CircleIcon = () => (
    <div
        style={{
            width: 8,
            height: 8,
            backgroundColor: "black",
            borderRadius: "50%",
            margin: 15,
        }}
    />
);

const items = [
    {
        key: "/home",
        icon: <CircleIcon />,
        label: "ACCUEIL",
    },
    {
        key: "/restorators",
        icon: <CircleIcon />,
        label: "LES RESTAURATEURS",
    },
    {
        key: "viens-jouer",
        icon: <CircleIcon />,
        label: "VIENS JOUER !",
        children: [
            {
                key: "/findmff",
                icon: <CircleIcon />,
                label: "OÙ EST MATT ?",
            },
            {
                key: "/clickmff",
                icon: <CircleIcon />,
                label: "ENVOIE LA SAUCE !",
            },
        ],
    },
    {
        key: "/infos",
        icon: <CircleIcon />,
        label: "INFOS PRATIQUES",
    },
    {
        key: "/contact",
        icon: <CircleIcon />,
        label: "CONTACT",
    },
];

const Sidebar = () => {
    const [collapsed, setCollapsed] = useState(true);
    const [menuHeight, setMenuHeight] = useState("100vh");
    const [isMobile, setIsMobile] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const updateDimensions = () => {
            setMenuHeight(`${window.innerHeight}px`);
            setIsMobile(window.innerWidth <= 768);
        };

        updateDimensions();
        window.addEventListener('resize', updateDimensions);

        return () => {
            window.removeEventListener('resize', updateDimensions);
        };
    }, []);

    const toggleCollapsed = () => {
        setCollapsed(!collapsed);
        document.body.style.overflow = !collapsed ? 'auto' : 'hidden';
    };

    return (
        <ConfigProvider
            theme={{
                components: {
                    Menu: {
                        collapsedIconSize: 0,
                        collapsedWidth: 0,
                        activeBarBorderWidth: 0,
                        fontSize: isMobile ? 18 : 22,
                        itemSelectedColor: "black",
                        itemSelectedBg: "#e0e0e0",
                        itemBg: "#f5f5f5",
                    },
                    Button: {
                        colorPrimary: "transparent",
                        colorPrimaryHover: "#d9d9d9",
                        colorPrimaryActive: "#949494",
                        paddingBlock: 25,
                    },
                },
            }}
        >
            <div
                style={{
                    position: "fixed",
                    top: 15,
                    left: 15,
                    zIndex: 1001,
                }}
            >
                <Button type="text" onClick={toggleCollapsed}>
                    <MenuOutlined
                        style={{ fontSize: "2.5rem", color: "black" }}
                    />
                </Button>
            </div>
            <div
                style={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: collapsed ? "transparent" : "#f5f5f5",
                    visibility: collapsed ? "hidden" : "visible",
                    transition: "background-color 0.3s ease-in-out",
                    zIndex: 1000,
                    overflowY: "auto",
                    height: menuHeight,
                }}
            >
                <div
                    style={{
                        display: "grid",
                        gridTemplateColumns: isMobile ? "1fr 90% 1fr" : "1fr 70% 1fr",
                        minHeight: menuHeight,
                        opacity: collapsed ? 0 : 1,
                        visibility: collapsed ? "hidden" : "visible",
                        transition: "opacity 0.3s ease-in-out",
                    }}
                >
                    <div></div>

                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            paddingTop: "20px",
                            paddingBottom: "20px",
                            width: "100%",
                        }}
                    >
                        <h1
                            className="poppins-bold"
                            style={{
                                textAlign: "center",
                                fontSize: isMobile ? "2rem" : "2.5rem",
                                paddingBottom: isMobile ? 30 : 60,
                            }}
                        >
                            MENU
                        </h1>

                        <Menu
                            className="poppins-bold"
                            onClick={({ key }) => {
                                if (key === location.pathname) {
                                    setCollapsed(true);
                                    document.body.style.overflow = 'auto';
                                    return;
                                }
                                if (key === "/contact") {
                                    window.open(
                                        "https://www.instagram.com/mulhouse.foodfestival",
                                    );
                                    setCollapsed(true);
                                    document.body.style.overflow = 'auto';
                                    return;
                                }
                                navigate(key);
                                setCollapsed(true);
                                document.body.style.overflow = 'auto';
                            }}
                            selectedKeys={[location.pathname]}
                            mode="inline"
                            items={items}
                            style={{
                                width: "100%",
                                paddingLeft: isMobile ? "10px" : "20px",
                                paddingRight: isMobile ? "10px" : "20px",
                            }}
                        />
                    </div>

                    <div></div>
                </div>
            </div>
        </ConfigProvider>
    );
};

export default Sidebar;