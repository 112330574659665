import React, { useEffect, useRef } from "react";

const CharacterCanvas = ({
    density,
    targetCharacter,
    images,
    movementFormula,
    speed,
    onTargetClick,
}) => {
    const canvasRef = useRef(null);

    useEffect(() => {
        const canvas = canvasRef.current;
        const context = canvas.getContext("2d");

        const resizeCanvas = () => {
            const width = canvas.parentElement.offsetWidth;
            const height = canvas.parentElement.offsetHeight;
            canvas.width = width;
            canvas.height = height;
        };

        // Initial resize
        resizeCanvas();

        const loadedImages = [];
        const positions = [];

        // Load images and initialize positions
        images.forEach((imageSrc) => {
            const img = new Image();
            img.src = imageSrc;
            loadedImages.push(img);
        });

        // Add the target character once
        const targetIndex = images.indexOf(targetCharacter.img);
        if (targetIndex !== -1) {
            const targetImg = loadedImages[targetIndex];
            positions.push({
                img: targetImg,
                x: Math.random() * canvas.width,
                y: Math.random() * canvas.height,
                dx: (Math.random() - 0.5) * speed,
                dy: (Math.random() - 0.5) * speed,
                isTarget: true,
            });
        }

        // Fill the rest with random characters, excluding the target character
        for (let i = 1; i < density; i++) {
            let randomIndex;
            do {
                randomIndex = Math.floor(Math.random() * loadedImages.length);
            } while (randomIndex === targetIndex);

            positions.push({
                img: loadedImages[randomIndex],
                x: Math.random() * canvas.width,
                y: Math.random() * canvas.height,
                dx: (Math.random() - 0.5) * speed,
                dy: (Math.random() - 0.5) * speed,
                isTarget: false,
            });
        }

        const draw = () => {
            context.clearRect(0, 0, canvas.width, canvas.height);

            positions.forEach((pos) => {
                const { img, x, y, dx, dy } = pos;
                const newX = (x + dx + canvas.width) % canvas.width;
                const newY = (y + dy + canvas.height) % canvas.height;

                context.drawImage(img, newX, newY, 50, 50);

                // Update position
                pos.x = newX;
                pos.y = newY;
                pos.dx = movementFormula(dx);
                pos.dy = movementFormula(dy);
            });

            requestAnimationFrame(draw);
        };

        draw();

        // Add event listener for window resize
        window.addEventListener("resize", resizeCanvas);

        // Add click event listener
        const handleClick = (event) => {
            const rect = canvas.getBoundingClientRect();
            const x = event.clientX - rect.left;
            const y = event.clientY - rect.top;

            positions.forEach((pos) => {
                if (pos.isTarget) {
                    const imgWidth = 50;
                    const imgHeight = 50;
                    if (
                        x >= pos.x &&
                        x <= pos.x + imgWidth &&
                        y >= pos.y &&
                        y <= pos.y + imgHeight
                    ) {
                        onTargetClick();
                    }
                }
            });
        };

        canvas.addEventListener("click", handleClick);
        return () => {
            // Cleanup function to stop the animation and remove event listeners
            cancelAnimationFrame(draw);
            window.removeEventListener("resize", resizeCanvas);
            canvas.removeEventListener("click", handleClick);
        };
    }, [
        density,
        targetCharacter,
        images,
        movementFormula,
        speed,
        onTargetClick,
    ]);

    return (
        <div style={{ width: "100%", height: "100%", position: "relative" }}>
            <canvas
                ref={canvasRef}
                style={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "15px",
                    boxSizing: "border-box",
                }}
            />
        </div>
    );
};

export default CharacterCanvas;
