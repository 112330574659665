import React from "react";
import { Typography, Row } from "antd";
import "../../Style/font.css";
import { ConfigProvider } from "antd";
import "./Infos.css";
import LogoMFF from "../../assets/character/menColor.svg";

const { Title } = Typography;

const TitleStyle = {
    color: "#CC2935",
    textAlign: "left",
    mixBlendMode: "multiply",
};

const Days = ({ day }) => (
    <Typography.Text
        style={{
            color: "var(--background-color)",
            backgroundColor: "var(--secondary-color)",
            padding: "0.3rem",
            borderRadius: "100rem",
            border: "3px solid var(--text-color)",
            margin: "0 0.2rem",
        }}
        className="poppins-bold pass-info"
    >
        {day}
    </Typography.Text>
);

const InfosPage = () => {
    return (
        <Row
            style={{
                display: "flex",
                position: "relative",
            }}
        >
            <div
                style={{
                    margin: "5vw",
                    marginTop: "8vh",
                    width: "100%",
                    alignItems: "center",
                }}
            >
                <ConfigProvider
                    theme={{
                        token: {
                            fontSize: 25,
                        },
                        components: {
                            Typography: {
                                titleMarginTop: 0,
                                titleMarginBottom: 0,
                            },
                        },
                    }}
                >
                    <Title className="anton-regular" style={TitleStyle}>
                        MOTOCO
                    </Title>
                    <Title
                        level={4}
                        className="anton-regular"
                        style={TitleStyle}
                    >
                        11, RUE DES BRODEUSES
                    </Title>
                    <Title className="anton-regular" style={TitleStyle}>
                        68200 MULHOUSE
                    </Title>
                </ConfigProvider>
            </div>
            <div
                style={{
                    margin: "5vw",
                    width: "100%",
                    alignItems: "center",
                    marginBottom: "3rem",
                }}
            >
                <ConfigProvider
                    theme={{
                        token: {
                            fontSize: 25,
                        },
                        components: {
                            Typography: {
                                titleMarginTop: 20,
                                titleMarginBottom: 7,
                            },
                        },
                    }}
                >
                    <Title className="anton-regular" style={TitleStyle}>
                        11H-22H
                    </Title>
                    <Typography.Text
                        style={{
                            color: "var(--text-color)",
                            backgroundColor: "var(--background-color)",
                            paddingLeft: "1rem",
                            paddingRight: "1rem",
                            borderRadius: "100rem",
                            border: "3px solid var(--text-color)",
                        }}
                        className="poppins-bold pass-info"
                    >
                        PASS JOURNÉE
                        <Days day={"7€"} />
                    </Typography.Text>
                    <Title
                        level={5}
                        className="anton-regular"
                        style={TitleStyle}
                    >
                        (11h-15h ou 16h-22h)
                    </Title>
                    <Typography.Text
                        style={{
                            color: "var(--text-color)",
                            backgroundColor: "var(--background-color)",
                            paddingLeft: "1rem",
                            paddingRight: "1rem",
                            borderRadius: "100rem",
                            border: "3px solid var(--text-color)",
                        }}
                        className="poppins-bold pass-info"
                    >
                        PASS DEMI JOURNÉE
                        <Days day={"4€"} />
                    </Typography.Text>
                    <br />
                    <br />
                    <Typography.Text
                        style={{
                            color: "var(--background-color)",
                            backgroundColor: "var(--primary-color)",
                            paddingLeft: "1rem",
                            paddingRight: "1rem",
                            borderRadius: "100rem",
                            border: "3px solid var(--text-color)",
                            fontSize: "1.5rem",
                        }}
                        className="poppins-bold"
                    >
                        GRATUIT -12ANS
                    </Typography.Text>
                    <br />
                    <br />
                    <Typography.Text
                        style={{
                            color: "var(--background-color)",
                            backgroundColor: "var(--primary-color)",
                            paddingLeft: "1rem",
                            paddingRight: "1rem",
                            borderRadius: "100rem",
                            border: "3px solid var(--text-color)",
                            fontSize: "1.5rem",
                        }}
                        className="poppins-bold"
                    >
                        ANIMAUX AUTORISÉS
                    </Typography.Text>
                </ConfigProvider>
            </div>

            <div className="svg-container">
                <img src={LogoMFF} alt="Logo" className="svg-image" />
            </div>
        </Row>
    );
};

export default InfosPage;
