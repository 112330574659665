import React from "react";
import { Col } from "antd";

import "./LegalMention.css";

const LegalMention = () => {
    return (
        <Col className="legal-mention-container">
            <h1>Mentions Légales</h1>
            <section>
                <h2>Éditeur du site</h2>
                <p>
                    Nom de l’association : <strong>Incr media</strong>
                    <br />
                    Statut : Association régie par la loi du 1er juillet 1901
                    <br />
                    Siège social :{" "}
                    <strong>28C rue François Spoerry 68100 Mulhouse</strong>
                    <br />
                    Numéro Amalia (Registre National des Associations) :{" "}
                    <strong>A2024MUL000005</strong>
                    <br />
                    Adresse e-mail : <strong>media.incr@gmail.com</strong>
                    <br />
                    Numéro de téléphone : <strong>+33 6 70 92 32 28</strong>
                </p>
            </section>

            <section>
                <h2>Directeur de la publication</h2>
                <p>
                    Le directeur de la publication est : <br />
                    <strong>LALLLEMANT Arnaud</strong>, en qualité de{" "}
                    <strong>Président</strong>.
                </p>
            </section>

            <section>
                <h2>Hébergement</h2>
                <p>
                    Le site est hébergé par : <br />
                    <strong>OVH</strong>
                    <br />
                    Adresse :{" "}
                    <strong>2 rue Kellermann - 59100 Roubaix - France</strong>
                    <br />
                    Numéro de téléphone : <strong>1007</strong>
                    <br />
                    Site web : <strong>www.ovhcloud.com/fr/</strong>
                </p>
            </section>

            <section>
                <h2>Propriété intellectuelle</h2>
                <p>
                    Le contenu du site (textes, images, graphismes, logo,
                    icônes, etc.) est la propriété exclusive de l'association{" "}
                    <strong>Incr media</strong>, sauf mention contraire
                    explicite. Toute reproduction, distribution, modification,
                    adaptation ou publication de ces éléments, même partielle,
                    est strictement interdite sans l'accord préalable écrit de
                    l'association.
                </p>
            </section>

            <section>
                <h2>Données personnelles</h2>
                <p>
                    Conformément au Règlement Général sur la Protection des
                    Données (RGPD) et à la loi Informatique et Libertés du 6
                    janvier 1978 modifiée, l'association{" "}
                    <strong>Incr media</strong> s'engage à protéger vos données
                    personnelles.
                </p>
                <ul>
                    <li>
                        <strong>Finalité du traitement :</strong> Les
                        informations collectées via le site sont utilisées
                        exclusivement pour{" "}
                        <strong>répondre aux demandes des utilisateurs</strong>.
                    </li>
                    <li>
                        <strong>Responsable du traitement :</strong>{" "}
                        <strong>Président</strong>.
                    </li>
                    <li>
                        <strong>Droits des utilisateurs :</strong> Vous disposez
                        d’un droit d’accès, de rectification, de suppression et
                        d’opposition concernant vos données personnelles. Pour
                        exercer ces droits, vous pouvez nous contacter à
                        l’adresse suivante :{" "}
                        <strong>media.incr@gmail.com</strong>.
                    </li>
                </ul>
            </section>

            <section>
                <h2>Cookies</h2>
                <p>
                    Le site peut utiliser des cookies pour améliorer
                    l’expérience utilisateur. En continuant à naviguer sur le
                    site, vous acceptez l’utilisation de cookies conformément à
                    notre politique de gestion des cookies. Vous pouvez
                    configurer vos préférences en matière de cookies via votre
                    navigateur.
                </p>
            </section>

            <section>
                <h2>Responsabilité</h2>
                <p>
                    L'association <strong>Incr media</strong> décline toute
                    responsabilité en cas de dommages résultant de l'accès ou de
                    l'utilisation du site, y compris toute détérioration ou
                    virus pouvant affecter l'équipement informatique ou tout
                    autre bien de l'utilisateur.
                </p>
            </section>

            <section>
                <h2>Litiges</h2>
                <p>
                    Tout litige en relation avec l’utilisation du site est
                    soumis au droit français. En cas de litige, compétence
                    exclusive est attribuée aux tribunaux compétents du ressort
                    du siège social de l'association.
                </p>
            </section>

            <section>
                <h2>Contact</h2>
                <p>
                    Pour toute question relative aux mentions légales, vous
                    pouvez nous contacter : <br />- Par e-mail :{" "}
                    <strong>media.incr@gmail.com</strong>
                    <br />- Par courrier :{" "}
                    <strong>28C rue François Spoerry 68100 Mulhouse</strong>
                    <br />- Par téléphone : <strong>+33 6 70 92 32 28</strong>
                </p>
            </section>
        </Col>
    );
};

export default LegalMention;
