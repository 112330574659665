import React, { useState, useEffect } from "react";
import "./SauceClicker.css";

import menColor from "../../assets/character/menColor.svg";
import yellowStains from "../../assets/sauce/yellowStains.png";
import yellowPot from "../../assets/sauce/yellowPot.png";

const SauceClicker = ({ onTargetClick, score, mouthStains }) => {
    const potPosition = {
        right: "-5vw",
    };

    return (
        <div className="sauce-clicker" onClick={onTargetClick}>
            <div className="sauce-clicker-container anton-regular">
                Score : {score}
                <div
                    style={{
                        position: "absolute",
                        zIndex: 2,
                        width: "50%",
                        ...potPosition,
                    }}
                >
                    <img src={yellowPot} alt="Pot de Sauce" className="pot" />
                </div>
                <div
                    className="character-container"
                    style={{ position: "relative" }}
                >
                    <img src={menColor} alt="Bonhomme" className="character" />
                    <div className="mouth">
                        {mouthStains.map((stain, index) => {
                            return (
                                <div
                                    key={index}
                                    className="stain-mouth"
                                    style={{
                                        zIndex: 10,
                                        top: stain.top,
                                        left: stain.left,
                                        opacity: 1,
                                        transition: "opacity 0.5s ease",
                                        backgroundImage: `url(${yellowStains})`,
                                    }}
                                />
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SauceClicker;
